import { attrs_input, rules } from "@/assets/variables";

export const items = [
    {
        key: "writer.name",
        term: "고객명",
        type: "text",
        rules: rules.name,
        required: true,
        placeholder: "고객명을 입력하세요.",
        ...attrs_input,
    },
    {
        key: "writer.phone",
        term: "연락처",
        type: "phone",
        rules: rules.phone,
        required: true,
        ...attrs_input,
    },
    {
        key: "writer.email",
        term: "이메일",
        type: "email",
        rules: rules.email,
        required: true,
        ...attrs_input,
    },
    {
        key: "subject",
        term: "문의제목",
        type: "text",
        rules: rules.subject,
        required: true,
        placeholder: "문의제목을 입력하세요.",
        ...attrs_input,
    },
    {
        key: "content",
        term: "문의내용",
        type: "textarea",
        rules: rules.content,
        placeholder: "문의내용을 입력하세요.",
        required: true,
        ...attrs_input,
    },
];
